import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

const PricingTable: React.FC = () => {
  const checkMrk: React.ReactElement<SVGSVGElement> = (
    <svg
      className="h-5 w-5 text-green-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );

  const dash: React.ReactElement<SVGSVGElement> = (
    <svg
      className="h-5 w-5 text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );

  const info: React.ReactElement<SVGSVGElement> = (
    <svg
      className="h-5 w-5 text-orange-500 inline mr-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <table className="w-full h-px table-fixed">
      <caption className="sr-only">
        <FormattedMessage id="pt_caption_1" />
      </caption>
      <thead className="border-t border-gray-200 divide-y divide-gray-200 bg-gray-50">
        <tr>
          <th
            className="py-4 pl-6 pr-6 text-lg leading-5 font-medium text-gray-900 text-left"
            scope="col"
          >
            <span className="sr-only">
              <FormattedMessage id="pt_r1_c1" />
            </span>
            <span>
              <FormattedMessage id="pt_r1_c2" />
            </span>
          </th>
          <th
            className="w-1/4 py-4 px-6 text-lg leading-6 font-extrabold text-gray-900 text-left"
            scope="col"
          >
            <FormattedMessage id="pt_r1_c3" />
          </th>
          <th
            className="w-1/4 py-4 px-6 text-lg leading-6 font-extrabold text-gray-900 text-left"
            scope="col"
          >
            <FormattedMessage id="pt_r1_c4" />
          </th>
          <th
            className="w-1/4 py-4 px-6 text-lg leading-6 font-extrabold text-gray-900 text-left"
            scope="col"
          >
            <FormattedMessage id="pt_r1_c5" />
          </th>
        </tr>
      </thead>
      <tbody className="border-t border-gray-200 divide-y divide-gray-200">
        <tr>
          <th
            className="py-8 pl-6 pr-6 align-top text-sm leading-5 font-medium text-gray-900 text-left"
            scope="row"
          >
            {/* <FormattedMessage id="pt_r2_c1" /> */}
          </th>
          <td className="h-full py-8 px-6 align-top">
            <div className="h-full flex flex-col justify-between">
              <div>
                <p>
                  <span className="text-xl leading-10 font-bold text-gray-900">
                    <FormattedMessage id="pt_r2_c2_1" />
                  </span>
                  <span className="text-base leading-6 font-medium text-gray-500">
                    <FormattedMessage id="pt_r2_c2_2" />
                  </span>
                </p>
                <p className="mt-4 text-sm leading-5 text-gray-500">
                  <FormattedMessage id="pt_r2_c2_3" />
                </p>
              </div>
              <Link
                to="/register"
                className="text-center mt-6 w-full bg-gradient-to-r from-green-500 to-teal-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-teal-600 focus:shadow-outline-gray transition duration-150 ease-in-out"
              >
                <FormattedMessage id="pt_r2_c2_4" />
              </Link>
            </div>
          </td>
          <td className="h-full py-8 px-6 align-top">
            <div className="h-full flex flex-col justify-between">
              <div>
                <p>
                  <span className="text-xl leading-10 font-bold text-gray-900">
                    <FormattedMessage id="pt_r2_c3_1" />
                  </span>
                  <span className="text-base leading-6 font-medium text-gray-500">
                    <FormattedMessage id="pt_r2_c3_2" />
                  </span>
                  <br />
                  <span className="text-xl leading-10 font-bold text-gray-900">
                    <FormattedMessage id="pt_r2_c3_3" />
                  </span>
                  <span className="text-base leading-6 font-medium text-gray-500">
                    <FormattedMessage id="pt_r2_c3_4" />
                  </span>
                  <br />
                  <span>
                    {info}
                    <span className="text-sm leading-6 font-medium text-gray-900">
                      <FormattedMessage id="pt_r2_c3_5" />
                    </span>
                  </span>
                </p>
                <p className="mt-4 text-sm leading-5 text-gray-500">
                  <FormattedMessage id="pt_r2_c3_6" />
                </p>
              </div>
              <Link
                to="/contact-us"
                className="mt-6 text-center w-full bg-gradient-to-r from-green-500 to-teal-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-teal-600 focus:shadow-outline-gray transition duration-150 ease-in-out"
              >
                <FormattedMessage id="pt_r2_c3_7" />
              </Link>
            </div>
          </td>
          <td className="h-full py-8 px-6 align-top">
            <div className="h-full flex flex-col justify-between">
              <div>
                <p>
                  <span className="text-xl leading-10 font-bold text-gray-900">
                    <FormattedMessage id="pt_r2_c4_1" />
                  </span>
                  <span className="text-base leading-6 font-medium text-gray-500">
                    <FormattedMessage id="pt_r2_c4_2" />
                  </span>
                  <br />
                  <span className="text-xl leading-10 font-bold text-gray-900">
                    <FormattedMessage id="pt_r2_c4_3" />
                  </span>
                  <span className="text-base leading-6 font-medium text-gray-500">
                    <FormattedMessage id="pt_r2_c4_4" />
                  </span>
                  <br />
                  <span>
                    {info}
                    <span className="text-sm leading-6 font-medium text-gray-900">
                      <FormattedMessage id="pt_r2_c4_5" />
                    </span>
                  </span>
                </p>
                <p className="mt-4 text-sm leading-5 text-gray-500">
                  <FormattedMessage id="pt_r2_c4_6" />{" "}
                  <span className="font-bold text-gray-700">
                    <FormattedMessage id="pt_r2_c4_7" />
                  </span>{" "}
                  <FormattedMessage id="pt_r2_c4_8" />
                </p>
              </div>
              {/* <button type="button" className="mt-6 w-full bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-pink-600 focus:shadow-outline-gray transition duration-150 ease-in-out">Buy Premium</button> */}
            </div>
          </td>
        </tr>
        <tr>
          <th
            className="py-3 pl-6 bg-gray-50 text-sm leading-5 font-medium text-gray-900 text-left"
            colSpan={4}
            scope="colgroup"
          >
            <FormattedMessage id="pt_r3_c1" />
          </th>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_r4_c1" />
          </th>
          <td className="py-5 px-6">
            {/* <span className="block text-sm leading-5 text-gray-700">Up to 5 users</span> */}
          </td>
          <td className="py-5 px-6">
            <span className="text-base leading-10 font-bold text-gray-900">
              3% + 30&cent; <FormattedMessage id="pt_r4_c3_1" />
            </span>
            <br />
            <span className="text-base leading-10 font-bold text-gray-900">
              1.75% <FormattedMessage id="pt_r4_c3_2" />
            </span>
            <br />
          </td>
          <td className="py-5 px-6">
            <span className="text-base leading-10 font-bold text-gray-900">
              3% + 30&cent; <FormattedMessage id="pt_r4_c3_3" />
            </span>
            <br />
            <span className="text-base leading-10 font-bold text-gray-900">
              1.75% <FormattedMessage id="pt_r4_c3_4" />
            </span>
            <br />
          </td>
        </tr>
        <tr>
          <th
            className="py-3 pl-6 bg-gray-50 text-sm leading-5 font-medium text-gray-900 text-left"
            colSpan={4}
            scope="colgroup"
          >
            <FormattedMessage id="pt_r5_c1" />
          </th>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_r6_c1" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_1" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_2" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_3" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_4" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_5" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_6" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-3 pl-6 bg-gray-50 text-sm leading-5 font-medium text-gray-900 text-left"
            colSpan={4}
            scope="colgroup"
          >
            <FormattedMessage id="pt_t_1" />
          </th>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_7" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c5" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_5" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c5" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_8" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c5" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_9" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c5" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_10" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_11" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_12" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_13" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_14" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_15" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_16" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_17" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_18" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: minus --> */}
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c6" />
            </span>
          </td>
          <td className="py-5 px-6">
            {dash}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c7" />
            </span>
          </td>
          <td className="py-5 px-6">
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
        </tr>
        <tr>
          <th
            className="py-3 pl-6 bg-gray-50 text-sm leading-5 font-medium text-gray-900 text-left"
            colSpan={4}
            scope="colgroup"
          >
            <FormattedMessage id="pt_t_2" />
          </th>
        </tr>
        <tr>
          <th
            className="py-5 pl-6 pr-6 text-sm leading-5 font-normal text-gray-500 text-left"
            scope="row"
          >
            <FormattedMessage id="pt_f_19" />
          </th>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c2" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c3" />
            </span>
          </td>
          <td className="py-5 px-6">
            {/* <!-- Heroicon name: check --> */}
            {checkMrk}
            <span className="sr-only">
              <FormattedMessage id="pt_r6_c4" />
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr className="border-t border-gray-200">
          <th className="sr-only" scope="row">
            <FormattedMessage id="pt_footer" />
          </th>
          <td className="pt-5 px-6">
            {/* <button type="button" className="w-full bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-pink-600 focus:shadow-outline-gray transition duration-150 ease-in-out">Buy Basic</button> */}
          </td>
          <td className="pt-5 px-6">
            <Link
              to="/contact-us"
              className="w-full block text-center bg-gradient-to-r from-green-500 to-teal-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-teal-600 focus:shadow-outline-gray transition duration-150 ease-in-out"
            >
              <FormattedMessage id="pt_btn_register" />
            </Link>
          </td>
          <td className="pt-5 px-6">
            {/* <button type="button" className="w-full bg-gradient-to-r from-green-500 to-teal-500 border border-transparent rounded-md shadow py-2 text-sm leading-5 font-semibold text-white hover:to-teal-600 focus:shadow-outline-gray transition duration-150 ease-in-out">Buy Premium</button> */}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default PricingTable;
