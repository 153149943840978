import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout/index";
import SEO from "../components/seo";
import PricingTable from "../components/pricingTable";
import PricingTableMobile from "../components/pricingTableMobile";

const PricingPage: React.FC = () => {
  return (
    <Layout>
      <SEO title={"Pricing"} description={"Pricing plans."} />
      <div className="max-w-screen-xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl leading-none font-extrabold text-gray-900 sm:text-center">
            <FormattedMessage id="title_pricing_page" />
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-500 sm:text-center">
            <FormattedMessage id="pricing_subtitle" />
          </p>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <PricingTableMobile />
          <div className="hidden lg:block">
            <PricingTable />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPage;
